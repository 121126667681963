const data = [
  {
    id: 'USERPA',
    icon: 'PersonOutlineOutlinedIcon',
    label: 'menu.user',
    to: '/',
    subs: [
      {
        id: 'USER-LOGOUT',
        label: 'menu.user-logout',
        icon: 'SettingsOutlinedIcon',
        to: '/user/logout'
      }
    ]
  },
  {
    id: 'DOCUMENTPA',
    icon: 'LocalMallOutlinedIcon',
    label: 'menu.documents',
    to: '/app/applications',
    subs: [
      {
        id: 'DOCUMENT-INVOICE',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.documents-invoice',
        to: '/app/documents/invoice'
      },
      {
        id: 'DOCUMENT-CONSULT',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.documents-select',
        to: '/app/documents/consult'
      },
      {
        id: 'DOCUMENT-DASHBOARD',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.documents-dashboard',
        to: '/app/documents/dashboard'
      }
    ]
  },
  {
    id: 'CREDITPA',
    icon: 'CreditCardOutlinedIcon',
    label: 'menu.authorization',
    to: '',
    subs: [
      {
        id: 'CREDIT-PINPAD',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.documents-authorization',
        to: '/app/documents/pinpad/authorization'
      },
      {
        id: 'CREDIT-PINPADMULTICOMERCE',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.documents-authorization-multicomerce',
        to: '/app/documents/pinpad/multicomerce'
      },
      {
        id: 'CREDIT-DIRECTA',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.documents-authorization-directa',
        to: '/app/documents/directa/authorization'
      }
    ]
  },
  {
    id: 'CAMPAIGNPA',
    icon: 'CardGiftcardOutlinedIcon',
    label: 'menu.campaign',
    to: '',
    subs: [
      {
        id: 'CAMPAIGN-EVENT',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.campaign-event',
        to: '/app/campaign/event'
      },
      {
        id: 'ROULETTE-EVENT',
        icon: 'CardGiftcardOutlinedIcon',
        label: 'menu.roulette-event',
        to: '/app/campaign/roulette'
      }
    ]
  },
  {
    id: 'PRODUCTSPA',
    icon: 'InventoryIcon',
    label: 'menu.product',
    to: '',
    subs: [
      {
        id: 'PRODUCT-CT',
        icon: 'SettingsOutlinedIcon',
        label: 'menu.product-inquiry',
        to: '/app/product'
      }
    ]
  },
  {
    id: 'SETTING',
    icon: 'SettingsOutlinedIcon',
    label: 'menu.setting',
    to: '/app/setting'
  }
];
export default data;
